import React, {useContext} from 'react';

//import motion
import { motion } from 'framer-motion';
// import transition
import {transition1} from '../transitions'
import { CursorContext } from '../context/CursorContext';

const Contact = () => {
  const {mouseEnterHandler, mouseLeaveHandler} = useContext(CursorContext);
  return <>
    <motion.section 
    initial={{ opacity:0, y: '100%' }} 
    animate={{ opacity:1, y:0 }} 
    exit={{ opacity:0, y:'100%' }}
    transition={transition1}
    className='section'>
    <div className='container mx-auto h-full'>
      <div className='flex flex-col lg:flex-row h-full items-center
      justify-start pt-36 gap-x-8 text-center lg:text-left'>
        {/* bg */}
        <motion.div 
          initial={{ opacity:0, y: '100%' }} 
          animate={{ opacity:1, y:0 }} 
          exit={{ opacity:0, y:'100%' }}
          transition={transition1}
          className='hidden lg:flex bg-[#eef7f9] absolute bottom-0 left-0
        right-0 top-72 -z-10'>
        </motion.div>
        {/* text & form */}
        <div 
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className='lg:flex-1 lg:pt-32 px-4'>
          <h1 className='h1'>
            Get In Touch
          </h1>
          <p className='mb-12'>
          If you have a general inquiry, would like to discuss a photoshoot or anything else, please don't hesitate to get in touch by the form below or by email <strong>emma.tysinger@gmail.com</strong>.
          </p>
          {/* form */}
          <form className='flex flex-col gap-y-4 group'
          action="https://formbold.com/s/3NrDn"
          method="POST"
          noValidate>
            <div className='flex flex-col md:flex-row gap-x-10 gap-y-4 '>
              <label for="name" className='w-full'>
                <input className='outline-none border-b border-b-primary 
                h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]' type='text' placeholder='Your name' id="name" name='name' required/>
              </label>
              <label for="email" className='w-full'>
                <input className='outline-none border-b border-b-primary 
                h-[60px] bg-transparent font-secondary w-full pl-3 
                placeholder:text-[#757879]
                invalid:[&:not(:placeholder-shown):not(:focus)] peer' type='email' placeholder='Your email address' id="email" name='email' 
                required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
                <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                  Please enter a valid email address
                </span>
              </label>
            </div>
            <input className='outline-none border-b border-b-primary 
              h-[60px] bg-transparent font-secondary w-full pl-3 
              placeholder:text-[#757879]' type='text' placeholder='Subject' id="subject" name="subject" required
            />
            <textarea className='outline-none border-b border-b-primary 
              h-[60px] bg-transparent font-secondary w-full pl-3 
              placeholder:text-[#757879]' type='text' placeholder='Your message' id="message" name="message" required
            />
            <button className='btn mb-[30px] mx-auto lg:mx-0 self-start hover:shadow-form group-invalid:pointer-events-none group-invalid:opacity-30'>
              Send Message
            </button>

          </form>
        </div>
      </div>
    </div>
  </motion.section>
  <footer className='flex footer w-full justify-center h-8 items-center'>
      <div className="text-sm text-primary">
      Copyright © 2025 
      </div>
  </footer>
  </>;
};
export {Contact};

