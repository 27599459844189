import React from 'react';

import About from '../pages/About'
import {Contact} from '../pages/Contact'
import {Work, WorkPeople, WorkPlaces, WorkOther} from '../pages/Work';
import Graduation from '../projects/Graduation';
import Portraits from '../projects/Portraits';
import Italy from '../projects/Italy';
import Como from '../projects/Como';
import Yellowstone from '../projects/Yellowstone';
import Boston from '../projects/Boston';
import NewEngland from '../projects/NewEngland';
import Architecture from '../projects/Architecture';
import Cars from '../projects/Cars';
import Nice from '../projects/Nice';
import CoteDAzur from '../projects/CoteDAzur';
import LowerAlps from '../projects/LowerAlps';
import Alps from '../projects/Alps';
import Interiors from '../projects/Interiors';
import NewYork from '../projects/NewYork';
import Film from '../projects/Film';
import Groups from '../projects/Groups';
import Hawaii from '../projects/Hawaii';
import Croatia from '../projects/Croatia';
import Oslo from '../projects/Oslo';
import Stockholm from '../projects/Stockholm';
import Copenhagen from '../projects/Copenhagen';
import Netherlands from '../projects/Netherlands';
import SGXV from '../projects/SGXV';
import Bordeaux from '../projects/Bordeaux';
import Vienna from '../projects/Vienna';
import Paris from '../projects/Paris';

//import routes rouate & uselocation hook
import {Routes, Route, useLocation} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

const AnimRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence initial={true} mode='wait'>
      <Routes key={location.pathname} location={location}>
        <Route path='/about' element={<About />} />
        <Route path='/' element={<Work />} />
        <Route path='/portfolio/places' element={<WorkPlaces />} />
        <Route path='/portfolio/people' element={<WorkPeople />} />
        <Route path='/portfolio/other' element={<WorkOther />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/graduation' element={<Graduation />} />
        <Route path='/portraits' element={<Portraits />} />
        <Route path='/italy' element={<Italy />} />
        <Route path='/como' element={<Como />} />
        <Route path='/yellowstone' element={<Yellowstone />} />
        <Route path='/boston' element={<Boston />} />
        <Route path='/newengland' element={<NewEngland />} />
        <Route path='/architecture' element={<Architecture />} />
        <Route path='/cars' element={<Cars />} />
        <Route path='/nice' element={<Nice />} />
        <Route path='/cotedazur' element={<CoteDAzur />} />
        <Route path='/summeralps' element={<LowerAlps />} />
        <Route path='/newyorkcity' element={<NewYork />} />
        <Route path='/interiors' element={<Interiors />} />
        <Route path='/winteralps' element={<Alps />} />
        <Route path='/film' element={<Film />} />
        <Route path='/groups' element={<Groups />} />
        <Route path='/hawaii' element={<Hawaii />} />
        <Route path='/croatia' element={<Croatia />} />
        <Route path='/oslo' element={<Oslo />} />
        <Route path='/stockholm' element={<Stockholm />} />
        <Route path='/copenhagen' element={<Copenhagen />} />
        <Route path='/netherlands' element={<Netherlands />} />
        <Route path='/sgxv' element={<SGXV />} />
        <Route path='/bordeaux' element={<Bordeaux />} />
        <Route path='/vienna' element={<Vienna />} />
        <Route path='/paris' element={<Paris />} />
      </Routes>
    </AnimatePresence>
    
  );
};

export default AnimRoutes;
