import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from "react-lazyload";
import { useMediaQuery } from 'react-responsive';

const Gallery = ({ data }) => {
    const [model, setModel] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const imageRefs = useRef(new Array(data.length).fill(null).map(() => React.createRef()));

    const isMdOrLarger = useMediaQuery({ query: '(min-width: 768px)' });

    const openGallery = (imgSrc, index) => {
        setSelectedImage(imgSrc);
        setCurrentIndex(index);
        setModel(true);
    }

    const closeGallery = () => {
        setSelectedImage(null);
        setCurrentIndex(null);
        setModel(false);
    }

    const showNextImage = () => {
        const newIndex = (currentIndex + 1) % data.length;
        setSelectedImage(data[newIndex]);
        setCurrentIndex(newIndex);
    }

    const showPreviousImage = () => {
        const newIndex = (currentIndex - 1 + data.length) % data.length;
        setSelectedImage(data[newIndex]);
        setCurrentIndex(newIndex);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const image = entry.target;
                        const src = image.dataset.src;
                        image.src = src;
                        observer.unobserve(image);
                    }
                });
            },
            { rootMargin: '0px', threshold: 0.1 }
        );

        imageRefs.current.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section className='section overflow-scroll'>
            <div className='items-center px-6 md:px-16 pt-[100px] lg:pt-[140px] relative'>
                <div className='lg:columns-3 md:columns-2 columns-1'>
                    {data.map((item, index) => {
                        return (
                            <div key={index} onClick={() => openGallery(item, index)}>
                                <img
                                    ref={imageRefs.current[index]}
                                    data-src={item}
                                    className='object-cover h-full w-full py-2'
                                    alt=''
                                    loading="lazy"
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            {model && (
                <div className='fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white bg-opacity-90 z-50' style={{ height: '100vh' }}>
                    <div className='flex justify-center items-center'>
                        {currentIndex > 0 && isMdOrLarger && (
                            <button
                                className='absolute left-1 md:left-3 lg:left-5 text-black text-3xl md:text-4xl font-primary focus:outline-none p-3'
                                onClick={showPreviousImage}
                            >
                                &larr;
                            </button>
                        )}
                        {/* Image */}
                        <img style={{ objectFit: 'contain', maxHeight: '90vh', maxWidth: '85vw' }} src={selectedImage} alt='' />
                        {currentIndex < data.length - 1 && isMdOrLarger && (
                            <button
                                className='absolute right-1 md:right-3 lg:right-5 text-black text-3xl md:text-4xl font-primary focus:outline-none p-3'
                                onClick={showNextImage}
                            >
                                &rarr;
                            </button>
                        )}
                    </div>
                    {/* Close button */}
                    <button
                        className='absolute left-2 md:left-3 lg:left-5 top-1 md:top-2 text-black text-5xl font-primary font-bold focus:outline-none p-3'
                        onClick={closeGallery}
                    >
                        &times;
                    </button>
                </div>
            )}
            <footer className='flex footer w-full justify-center h-8 items-center'>
                <div className="text-sm text-primary">
                    Copyright © 2025 
                </div>
            </footer>
        </section>
    );
};

export default Gallery;
