import React from 'react';
import Portfolio from '../components/Portfolio'
//import images

import cover1 from '../img/work/cover1.jpg'
import cover2 from '../img/work/cover2.jpg'
import cover3 from '../img/work/cover3.jpg'
import cover4 from '../img/work/cover4.jpg'
import cover5 from '../img/work/cover5.jpg'
import cover6 from '../img/work/cover6.jpg'
import cover7 from '../img/work/cover7.jpg'
import cover8 from '../img/work/cover8.jpg'
import cover9 from '../img/work/cover9.jpg'
import cover10 from '../img/work/cover10.jpg'
import cover11 from '../img/work/cover11.jpg'
import cover12 from '../img/work/cover12.jpg'
import cover13 from '../img/work/cover13.jpg'
import cover14 from '../img/work/cover14.jpg'
import cover15 from '../img/work/cover15.jpg'
import cover16 from '../img/work/cover16.jpg'
import cover17 from '../img/work/cover17.jpg'
import cover18 from '../img/work/cover18.jpg'
import cover22 from '../img/work/cover22.jpg'
import cover23 from '../img/work/cover23.jpg'
import cover24 from '../img/work/cover24.jpg'
import cover25 from '../img/work/cover25.jpg'
import cover26 from '../img/work/cover26.jpg'
import cover27 from '../img/work/cover27.jpg'
import cover28 from '../img/work/cover28.jpg'
import cover29 from '../img/work/cover29.jpg'
import cover30 from '../img/work/cover30.jpg'

const Work = () => {
    let data = [
        { id: 1, imgSrc: cover8, coverName: "Côte D'Azur", link:'/cotedazur'},
        { id: 2, imgSrc: cover14, coverName: 'Winter Alps', link:'/winteralps'},
        { id: 3, imgSrc: cover9, coverName: 'Nice', link:'/nice'},
        { id: 4, imgSrc: cover25, coverName: 'Denmark', link:'/copenhagen'},
        { id: 5, imgSrc: cover5, coverName: 'Lake Como', link:'/como'},
        { id: 6, imgSrc: cover22, coverName: 'Croatia', link:'/croatia'},
        { id: 7, imgSrc: cover24, coverName: 'Stockholm', link:'/stockholm'},
        { id: 8, imgSrc: cover1, coverName: 'Boston', link:'/boston'},
        { id: 28, imgSrc: cover30, coverName: 'Paris', link:'/paris'},
        { id: 9, imgSrc: cover26, coverName: 'The Netherlands', link:'/netherlands'},
        { id: 20, imgSrc: cover12, coverName: 'Interiors', link:'/interiors'},
        { id: 10, imgSrc: cover13, coverName: 'New York City', link:'/newyorkcity'},
        { id: 11, imgSrc: cover2, coverName: 'Yellowstone', link:'/yellowstone'},
        { id: 12, imgSrc: cover4, coverName: 'Architecture', link:'/architecture'},
        { id: 26, imgSrc: cover28, coverName: 'Vienna', link:'/vienna'},
        { id: 13, imgSrc: cover29, coverName: 'Saint Gilles\nCroix de Vie', link:'/sgxv'},
        { id: 21, imgSrc: cover15, coverName: 'Film', link:'/film'},
        { id: 25, imgSrc: cover27, coverName: 'Bordeaux', link:'/bordeaux'},
        { id: 14, imgSrc: cover23, coverName: 'Oslo', link:'/oslo'},
        { id: 15, imgSrc: cover10, coverName: 'Summer Alps', link:'/summeralps'},
        { id: 16, imgSrc: cover6, coverName: 'New England', link:'/newengland'},
        { id: 17, imgSrc: cover7, coverName: 'Italy', link:'/italy'},
        { id: 18, imgSrc: cover18, coverName: 'Hawaii', link:'/hawaii'},
        { id: 19, imgSrc: cover3, coverName: 'Graduation', link:'/graduation'},
        { id: 22, imgSrc: cover11, coverName: 'Cars', link:'/cars'},
        { id: 23, imgSrc: cover17, coverName: 'Groups', link:'/groups'},
        { id: 24, imgSrc: cover16, coverName: 'Portraits', link:'/portraits'},
    ];

    return (
        <Portfolio data={data} />
    );
};

const WorkPlaces = () => {
    let data = [
        { id: 1, imgSrc: cover8, coverName: "Côte D'Azur", link:'/cotedazur'},
        { id: 2, imgSrc: cover14, coverName: 'Winter Alps', link:'/winteralps'},
        { id: 3, imgSrc: cover9, coverName: 'Nice', link:'/nice'},
        { id: 4, imgSrc: cover25, coverName: 'Denmark', link:'/copenhagen'},
        { id: 5, imgSrc: cover5, coverName: 'Lake Como', link:'/como'},
        { id: 6, imgSrc: cover22, coverName: 'Croatia', link:'/croatia'},
        { id: 7, imgSrc: cover24, coverName: 'Stockholm', link:'/stockholm'},
        { id: 28, imgSrc: cover30, coverName: 'Paris', link:'/paris'},
        { id: 8, imgSrc: cover1, coverName: 'Boston', link:'/boston'},
        { id: 9, imgSrc: cover26, coverName: 'The Netherlands', link:'/netherlands'},
        { id: 10, imgSrc: cover13, coverName: 'New York City', link:'/newyorkcity'},
        { id: 11, imgSrc: cover2, coverName: 'Yellowstone', link:'/yellowstone'},
        { id: 26, imgSrc: cover28, coverName: 'Vienna', link:'/vienna'},
        { id: 12, imgSrc: cover29, coverName: 'Saint Gilles\nCroix de Vie', link:'/sgxv'},
        { id: 25, imgSrc: cover27, coverName: 'Bordeaux', link:'/bordeaux'},
        { id: 13, imgSrc: cover10, coverName: 'Summer Alps', link:'/summeralps'},
        { id: 14, imgSrc: cover23, coverName: 'Oslo', link:'/oslo'},
        { id: 15, imgSrc: cover6, coverName: 'New England', link:'/newengland'},
        { id: 16, imgSrc: cover7, coverName: 'Italy', link:'/italy'},
        { id: 17, imgSrc: cover18, coverName: 'Hawaii', link:'/hawaii'},
        
        
    ];

    return (
        <Portfolio data={data} />
    );
};

const WorkPeople = () => {
    let data = [
        { id: 1, imgSrc: cover3, coverName: 'Graduation', link:'/graduation'},
        { id: 2, imgSrc: cover16, coverName: 'Portraits', link:'/portraits'},
        { id: 3, imgSrc: cover17, coverName: 'Groups', link:'/groups'},
    ];

    return (
        <Portfolio data={data} />
    );
};

const WorkOther = () => {
    let data = [
        { id: 1, imgSrc: cover4, coverName: 'Architecture', link:'/architecture'},
        { id: 2, imgSrc: cover12, coverName: 'Interiors', link:'/interiors'},
        { id: 3, imgSrc: cover15, coverName: 'Film', link:'/film'},
        { id: 4, imgSrc: cover11, coverName: 'Cars', link:'/cars'},
        
    ];

    return (
        <Portfolio data={data} />
    );
};

export { Work };
export { WorkPlaces };
export { WorkPeople };
export { WorkOther };
    